<template>
  <div id="div_tax_area">
    <h2 class="content_title">{{ $t('msg.ADD20220219.019') }}</h2> <!-- 세금계산서 공급 받는 자 -->
    <table class="tbl_row">
      <colgroup>
        <col width="130">
        <col width="45%">
        <col width="120">
        <col width="55%">
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('msg.ADD20220219.020') }}</th> <!-- 기존정보 불러오기 -->
          <td colspan="3">
            <select :style="customInputWidth" v-model="taxTemplateSelect">
              <option :value="9999">{{ $t('msg.SETT010T010.050') }}<!-- 직접입력 --></option>
              <option v-for="(template, i) in taxTemplateList" :value="i" :key="'tax-template-option-' + i">{{ template.templateNm }}</option>
            </select>

            <a class="button sm mr2 ml2" href="javascript:void(0)" @click="taxTemplateAlert('I')">{{ $t('msg.CSCT060T070.033') }}</a><!-- 등록 -->
            <a class="button sm mr2" href="javascript:void(0)" @click="taxTemplateAlert('U')">{{ $t('msg.CSCT060T070.016') }}</a><!-- 수정 -->
            <a class="button sm" href="javascript:void(0)" @click="taxTemplateAlert('D')">{{ $t('msg.ADMN040G020.016') }}</a><!-- 삭제 -->
          </td>
        </tr>
        <tr>
          <th>{{ $t('msg.ONIM050G020.009') }}</th><!-- 상호 -->
          <td colspan="3">
            <input
              type="text"
              :style="customInputWidth"
              v-model="taxTemplate.rcivNm"
              id="fbt-rcivNm"
              maxlength="30"
            >
          </td>
        </tr>
        <tr>
          <th>{{ $t('msg.VOSD100_M5.066') }}</th> <!-- 주소 -->
          <td colspan="3">
            <input
              type="text"
              v-model="taxTemplate.rcivAddr"
              id="fbt-rcivAddr"
              maxlength="70"
            >
          </td>
        </tr>
        <tr>
          <th>{{ $t('art.CMATK019') }} </th> <!-- 사업자 번호 -->
          <td>
            <input
              type="text"
              v-model="taxTemplate.rcivBzrgNo"
              id="fbt-rcivBzrgNo"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="20"
            >
          </td>
          <th>{{ $t('msg.ADD20220219.022') }}</th> <!-- 사업자 등록증 -->
          <td>
            <input type="text" class="upload_name" v-model="uploadFileName" readonly @click="fileUploadAlert()">
            <a :class="uploadFileInfo.length > 0 ? 'button sm blue' : 'button sm gray'" href="javascript:void(0)" @click="fileUploadAlert()">{{ $t('msg.ONEX060P012.055') }}</a> <!-- 파일첨부 -->
            <span id="fbt-file"></span>
            <div style="color: red; font-weight: bold;">※ {{ $t('tem.CMAT038') }} </div>
          </td>
        </tr>
        <tr>
          <th>{{ $t('msg.ADD20220219.021') }}</th> <!-- 종사업자 번호 -->
          <td colspan="3">
            <input
              type="text"
              :style="customInputWidth"
              v-model="taxTemplate.tpopBsnCd"
              id="fbt-tpopBsnCd"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="4"
            >
          </td>
        </tr>
        <tr>
          <th>email</th>
          <td colspan="3">
            <input
              type="text"
              :style="customInputWidth"
              v-model="taxTemplate.emlAddr"
              id="fbt-emlAddr"
              maxlength="200"
            >

            <input type="radio" id="extblIssYn-N" v-model="taxTemplate.extblIssYn" value="N">
            <label for="extblIssYn-N" class="mr20"><span></span>{{ $t('msg.ADD20220219.023') }}</label> <!-- 본인 -->

            <input type="radio" id="extblIssYn-Y" v-model="taxTemplate.extblIssYn" value="Y">
            <label for="extblIssYn-Y"><span></span>{{ $t('msg.ADD20220219.024') }}</label><!-- 대행 -->
          </td>
        </tr>
        <tr>
          <th>{{ $t('msg.ONIM070P020.005') }} </th> <!-- 담당자 -->
          <td>
            <input
              type="text"
              v-model="taxTemplate.rcivPicNm"
              id="fbt-rcivPicNm"
              maxlength="30"
            >
          </td>
          <th>{{ $t('msg.CSBK100.161') }} </th> <!-- 연락처 -->
          <td>
            <input
              type="text"
              v-model="taxTemplate.rcivCtfc"
              id="fbt-rcivCtfc"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="100"
            >
          </td>
        </tr>
        <tr>
          <th>발행여부</th> <!-- 합산발행여부 -->
          <td>
            <input type="radio" id="rcvProcCatCd-01" v-model="taxTemplate.rcvProcCatCd" value="01">
            <label for="rcvProcCatCd-01" class="mr20"><span></span>{{ $t('msg.ADD20220219.026') }}</label> <!-- 건별발행 -->
          </td>
          <th>{{ $t('msg.ADD20220219.028') }}</th> <!-- 계산서 발행일 -->
          <td>
            <span v-show="false">
              <input type="radio" id="acshIssKind-01" :disabled="isDisableAcshIssDt" v-model="taxTemplate.acshIssKind" value="01">
              <label for="acshIssKind-01" class="mr20"><span></span>{{ $t('msg.MYIN020T010.006') }}</label><!-- 입금일 -->
              <input type="radio" id="acshIssKind-02" :disabled="isDisableAcshIssDt" v-model="taxTemplate.acshIssKind" value="02">
              <label for="acshIssKind-02"><span></span>{{ $t('msg.SETT010T010.050') }}<!-- 직접입력 --></label>
            </span>
            <div class="input_calendar mid">
              <e-date-picker
                :disabled="isDisableCalendar"
                @input="chanageAcshIssDt"
                :date-range="dateRange"
                v-model="taxTemplate.acshIssDt"
                :readonly="true"
              />
            </div>
            <span id="fbt-acshIssDt"></span>
          </td>
        </tr>
        <tr v-if="(btnType === 'DO' || btnType === 'BL') && taxTemplate.reqAddCont">
          <td style="border-left: 0px"></td> <!-- 합산발행 -->
          <td colspan="3" style="border-left: 0px">
            <span>{{ `${$t('msg.ADD20220219.055')} "${taxTemplate.reqAddCont}" ${$t('msg.ADD20220219.056')} ` }}</span>
          </td>
        </tr>
        <tr v-if="btnType !== 'DO' && btnType !== 'BL'">
          <th>{{ $t('msg.SETT010T010.051') }}<!-- 직접입력 --></th>
          <td colspan="3">
            <div v-if="taxTemplate.reqAddCont" style="margin-bottom: 2px">
              <span>{{ `${$t('msg.ADD20220219.055')} "${taxTemplate.reqAddCont}" ${$t('msg.ADD20220219.056')} ` }}</span>
            </div>
            <textarea
              id="taxReqCont"
              v-model="taxTemplate.reqCont"
              @keyup="$ekmtcCommon.fnKeyupByteCheck($event, 2000)"
            >
            </textarea>
            <div class="div_req_byte">
              <span class="color_black font_medium" id="byte_taxReqCont">0</span>
              <span class="color_lightgray">/</span>
              <span class="color_lightgray">2,000</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="fb_dext5_pop" v-show="isShowDext5Pop">
      <div class="popup_dim" ref="popup">
        <div class="popup_wrap" style="width:800px; height:520px;">
          <button class="layer_close" @click="closeFileUploadr()">close</button>
          <div class="popup_cont">
            <h1 class="page_title">{{ $t('msg.FARE020T010.009') }}<!-- 직접입력 --></h1>
            <div class="content_box">
              <DEXT5Upload
                ref="dextupload"
                v-if="isShowDext5"
                :id="'dext5-uploader-fb-' + dext5Idx"
                :single="true"
                :category="false"
                width="100%"
                height="200px"
                @completed="uploadFile"
                :file-info="uploadFileInfo"
                :category-info="[]"
              />
            </div>
          </div>
          <div class="mt10 text_center">
            <a class="button blue lg" @click="closeFileUploadr()">{{ $t('msg.MYIN050T010.057') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import CalcIssue from '@/api/rest/settle/calcIssue'
import DEXT5Upload from '@/components/DEXT5Upload'
import EDatePicker from '@/components/common/EDatePickerCustom'
export default {
  name: 'VirtualAccountFreightBillTax',
  components: {
    DEXT5Upload,
    EDatePicker
  },
  props: [
    'errArr',
    'clearArr',
    'validationTaxTemplate',
    'dpId',
    'inputDefaultData',
    'issueDtInfo',
    'btnType',
    'blList'
  ],
  data: function () {
    return {
      taxTemplateList: [],
      taxTemplate: {
        tplRno: null,
        rcivNm: '',
        rcivAddr: '',
        rcivBzrgNo: '',
        tpopBsnCd: '',
        rcivPicNm: '',
        rcivCtfc: '',
        emlAddr: '',
        extblIssYn: 'N',
        rcvProcCatCd: '01',
        acshIssKind: '02',
        acshIssDt: '',
        reqCont: '',
        reqAddCont: '', // 요청내용의 추가 내용(합산발행 선택시 요청내용 앞에 추가될 내용)
        file: null
      },
      beforeIssDt: '',
      taxTemplateSelect: 9999,
      uploadFileInfo: [],
      uploadFileName: '',
      isShowDext5: true,
      isShowDext5Pop: true,
      dext5Idx: 0,
      isDisableAcshIssDt: false,
      isDisableCalendar: false,
      dateRange: {
        min: '',
        max: ''
      },
      customInputWidth: {
        width: '36.3% !important'
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    uploadFileInfo () {
      if (this.uploadFileInfo.length > 0) {
        this.uploadFileName = this.uploadFileInfo[0].originalName
      } else {
        this.uploadFileName = ''
      }
    },
    taxTemplateSelect: function () {
      this.setTaxTemplate()
    },
    btnType: function () {
      if (this.btnType === 'DO') {
         this.setDoAcshIssDt()
      }
    },
    taxTemplate: {
      deep: true,
      handler () {
         this.validationTaxTemplate(this.taxTemplate, 'inputData')
        //  if (this.taxTemplate.acshIssKind !== '02' || this.btnType === 'DEMDET' || this.btnType === 'OFC' || this.btnType === 'RETURN') {
        //    this.isDisableCalendar = true
        //  } else {
        //    this.isDisableCalendar = false
        //  }
      }
    },
    'taxTemplate.rcvProcCatCd': {
      deep: true,
      handler () {
        // 개별발행, 합산발행 변경에 따른 처리
        console.log('>>>>> FreightBillTax.vue watch taxTemplate.rcvProcCatCd this.btnType : ' + this.btnType)
        //if (this.blList && this.blList.length > 1) {
        if (this.blList) {
          // let blNoStr = ''
          // // 합산발행(02) 인 경우 합산발행 : 합산 BlNo
          // if (this.taxTemplate.rcvProcCatCd === '02') {
          //   let arrBlNo = []
          //   for (let blInfo of this.blList) {
          //     arrBlNo.push(blInfo.blNo)
          //   }
          //   blNoStr = this.$t('msg.ADD20220219.027') + ' : ' + arrBlNo.join(', ')
          // }
          //
          // if (this.btnType === 'DO' || this.btnType === 'BL') {
          //   // DO 또는 BL 인 경우
          //   this.$emit('setReqCont', blNoStr, this.taxTemplate.rcvProcCatCd)
          // } else if (this.btnType === 'TAX_INVOICE') {
          //   if (this.taxTemplate.rcvProcCatCd === '02') {
          //     if (this.taxTemplate.reqCont.indexOf(blNoStr) < 0) {
          //       // 입금/계산서 발행시
          //       this.taxTemplate.reqCont = blNoStr + '\r\n' + this.taxTemplate.reqCont
          //     }
          //   }
          // }

          // 합산발행(02) 인 경우 합산발행 : 합산 BlNo
          if (this.taxTemplate.rcvProcCatCd === '02') {
            let arrBlNo = []
            for (let blInfo of this.blList) {
              arrBlNo.push(blInfo.blNo)
            }
            this.taxTemplate.reqAddCont = `${this.$t('msg.ADD20220219.027')} : ${arrBlNo.join(', ')}`
          } else {
            this.taxTemplate.reqAddCont = ''
          }
        }
      }
    },
    errArr: {
      deep: true,
      handler () {
        for (let err of this.errArr) {
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + err.id), err.msg)
        }
      }
    },
    clearArr: {
      deep: true,
      handler () {
        for (let id of this.clearArr) {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + id))
        }
      }
    },
    issueDtInfo: {
      deep: true,
      handler () {
        // 입금일, 온보드일자 변경과 관계없이 계산서발행일자는 미래날짜만 선택 불가능하도록 변경하기 위해서 아래 조건은 주석처리 2022.02.18

        // console.log(this.issueDtInfo)
        // if (this.btnType !== 'DO' && this.issueDtInfo.isUse) {
        //   if (!this.issueDtInfo.isFast) {
        //     this.isDisableAcshIssDt = true
        //     this.taxTemplate.acshIssKind = '01'

        //     if (this.btnType !== 'TAX_INVOICE') {
        //       this.taxTemplate.acshIssDt = this.issueDtInfo.obrdDt.toString()
        //     }
        //     this.dateRange = {
        //       min: '',
        //       max: ''
        //     }
        //   } else {
        //     this.isDisableAcshIssDt = false
        //     this.taxTemplate.acshIssKind = '02'
        //     this.dateRange = {
        //       min: this.issueDtInfo.depositDt.toString().substring(0, 4) + '.' +
        //             this.issueDtInfo.depositDt.toString().substring(4, 6) + '.' +
        //             this.issueDtInfo.depositDt.toString().substring(6, 8),
        //       max: this.issueDtInfo.obrdDt.toString().substring(0, 4) + '.' +
        //             this.issueDtInfo.obrdDt.toString().substring(4, 6) + '.' +
        //             this.issueDtInfo.obrdDt.toString().substring(6, 8)
        //     }
        //   }
        // }
      }
    }

  },
  created () {
    this.resetDext5()
  },
  async mounted () {
    await this.getTaxTemplateList()
    this.setInputData()
    // if (this.btnType === 'DEMDET' || this.btnType === 'OFC') {
    //   this.setDemDetAcshIssDt()
    // } else
    if (this.btnType === 'RETURN') {
      this.setDoAcshIssDt()
      if (this.inputDefaultData !== undefined && this.inputDefaultData !== null && this.inputDefaultData.acshIssDt !== undefined && this.inputDefaultData.acshIssDt !== null && this.inputDefaultData.acshIssDt !== '') {
        let taxTemplate = JSON.parse(JSON.stringify(this.inputDefaultData))
        this.taxTemplate.acshIssDt = taxTemplate.acshIssDt
      } else {
        let today = this.dateFormat(new Date())
        this.taxTemplate.acshIssDt = today.replace(/\./g, '')
      }
      this.isDisableAcshIssDt = true
      this.isDisableCalendar = true
    } else {
      let today = this.dateFormat(new Date())
      this.taxTemplate.acshIssDt = today.replace(/\./g, '')
      this.setDoAcshIssDt()
    }
  },
  methods: {
    setBusinessInfo (businessInfo) {
      this.taxTemplate.rcivAddr = businessInfo.addr
      this.taxTemplate.rcivBzrgNo = businessInfo.bzrgNo
      this.taxTemplate.rcivNm = businessInfo.bsnNm
    },
    setDemDetAcshIssDt () {
      let today = this.dateFormat(new Date())
      this.taxTemplate.acshIssDt = today.replace(/\./g, '')
      this.isDisableAcshIssDt = true
    },
    setDoAcshIssDt () {
      let today = this.dateFormat(new Date())
      let lastMonth = this.getLastMonth()
      this.taxTemplate.acshIssDt = today.split('.')[0] + today.split('.')[1] + today.split('.')[2]
      if (parseInt(today.split('.')[2]) < 8) {
        this.dateRange = {
          min: lastMonth.split('.')[0] + '.' + lastMonth.split('.')[1] + '.01',
          max: today
        }
      } else {
        this.dateRange = {
          min: today.split('.')[0] + '.' + today.split('.')[1] + '.01',
          max: today
        }
      }
    },
    dateFormat (dt) {
      let year = dt.getFullYear() //연도
      let month = ('0' + (dt.getMonth() + 1)).slice(-2) //월
      let date = ('0' + dt.getDate()).slice(-2) //날짜

      return year + '.' + month + '.' + date
    },
    getLastMonth () {
      let date = new Date()
      let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)

      let lastMonth = new Date(firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 1))

      return this.dateFormat(lastMonth)
    },
    async getTaxTemplateList () {
      await CalcIssue.getTaxTemplateList()
      .then(response => {
        this.taxTemplateSelect = 9999
        this.taxTemplateList = response.data
      }).catch(e => {
        console.log(e)
      })
    },
    setInputData () {
      if (this.$_.isEmpty(this.inputDefaultData)) {
        console.log('template ' + this.$t('msg.ADD20220219.030')) // '내용이 없습니다.'
        return
      }
      let taxTemplate = JSON.parse(JSON.stringify(this.inputDefaultData))
      console.log(taxTemplate)
      if (!this.isEmpty(taxTemplate)) {
        this.taxTemplate.tplRno = taxTemplate.reqRno
        this.taxTemplate.rcivNm = taxTemplate.rcivNm
        this.taxTemplate.rcivBzrgNo = taxTemplate.rcivBzrgNo
        this.taxTemplate.rcivAddr = taxTemplate.rcivAddr
        this.taxTemplate.rcivPicNm = taxTemplate.rcivPicNm
        this.taxTemplate.reqCont = taxTemplate.reqCont
        this.taxTemplate.rcivCtfc = taxTemplate.rcivCtfc
        this.taxTemplate.emlAddr = taxTemplate.emlAddr
        this.taxTemplate.tpopBsnCd = taxTemplate.tpopBsnCd
        this.taxTemplate.extblIssYn = taxTemplate.extblIssYn
        this.taxTemplate.rcvProcCatCd = taxTemplate.rcvProcCatCd
        if (!this.isEmpty(taxTemplate.fileNm)) {
          this.uploadFileInfo = [{
            reqRno: taxTemplate.reqRno,
            guid: '1',
            originalName: taxTemplate.fileNm,
            size: taxTemplate.fileSz,
            fileId: 'http://settle.ekmtc:9000/settle/settle/file-download?reqRno=' + taxTemplate.reqRno + '&type=BUSINESS_LICENSE',
            status: 'complete',
            contentType: 'image/png',
            category: '',
            isNew: false
          }]
          this.uploadFileName = taxTemplate.fileNm
          this.thumbnail_type = 'input'
        }
      }
    },
    taxTemplateAlert (type) {
      let obj = {
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.ONEX010P100.036'), // 닫기
        customConfirmBtnText: this.$t('msg.ONEX010P100.034'), //'확인',
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        type: 'info'
      }
      switch (type) {
        case 'I':
          obj.onConfirm = this.insertTaxTemplate
          obj.message = `<div>${this.$t('msg.SETT010T010.124')}</div><br/><input type="text" id="templateNm" />`
          break
        case 'U':
          obj.onConfirm = this.updateTaxTemplate
          obj.message = `<div>${this.$t('msg.SETT010T010.124')}</div><br/><input type="text" value="${this.taxTemplateList[this.taxTemplateSelect].templateNm}" id="templateNm" />`
          break
        case 'D':
          obj.onConfirm = this.deleteTaxTemplate
          // obj.message = 'Template ' + this.$t('msg.ADMN030G020.025') // 삭제하시겠습니까?
          obj.message = this.$t('msg.SETT010T010.125') // 삭제하시겠습니까?
          break
      }
      this.$ekmtcCommon.alert(obj)
    },
    insertTaxTemplate () {
      let req = JSON.parse(JSON.stringify(this.taxTemplate))
      req.templateNm = document.getElementById('templateNm').value
        CalcIssue.insertTaxTemplate(this.parseTaxTemplateReq(req)).then(response => {
        console.log('등록!')
        this.comAlert(this.$t('msg.SETT010T010.127')) // 등록되었습니다
        this.getTaxTemplateList()
      }).catch(e => {
        console.log(e)
      })
    },
    updateTaxTemplate () {
      let req = JSON.parse(JSON.stringify(this.taxTemplate))
      req.templateNm = document.getElementById('templateNm').value
      CalcIssue.updateTaxTemplate(this.parseTaxTemplateReq(req)).then(response => {
        this.comAlert('Template' + this.$t('tem.CMAT014')) // 수정되었습니다
        this.getTaxTemplateList()
      }).catch(e => {
        console.log(e)
      })
    },
    deleteTaxTemplate () {
      let req = JSON.parse(JSON.stringify(this.taxTemplate))
      CalcIssue.deleteTaxTemplate(this.parseTaxTemplateReq(req)).then(response => {
        this.comAlert(this.$t('msg.SETT010T010.126')) // 삭제되었습니다
        this.getTaxTemplateList()
      }).catch(e => {
        console.log(e)
      })
    },
    parseTaxTemplateReq (req) {
      return req
    },
    setTaxTemplate () {
      if (this.taxTemplateSelect === 9999) {
        this.resetTaxTemplate()
      } else {
        const beforeIssDt = this.taxTemplate.acshIssDt
        this.beforeIssDt = this.taxTemplate.acshIssDt
        this.taxTemplate = {
          tplCatCd: this.taxTemplateList[this.taxTemplateSelect].tplCatCd,
          tplRno: this.taxTemplateList[this.taxTemplateSelect].tplRno,
          rcivNm: this.taxTemplateList[this.taxTemplateSelect].rcivNm,
          rcivAddr: this.taxTemplateList[this.taxTemplateSelect].rcivAddr,
          rcivBzrgNo: this.taxTemplateList[this.taxTemplateSelect].rcivBzrgNo,
          tpopBsnCd: this.taxTemplateList[this.taxTemplateSelect].tpopBsnCd,
          rcivPicNm: this.taxTemplateList[this.taxTemplateSelect].rcivPicNm,
          rcivCtfc: this.taxTemplateList[this.taxTemplateSelect].rcivCtfc,
          emlAddr: this.taxTemplateList[this.taxTemplateSelect].emlAddr,
          extblIssYn: 'N',
          rcvProcCatCd: '01',
          acshIssKind: '02',
          acshIssDt: this.beforeIssDt,
          reqCont: '',
          file: null
        }
      }
      this.uploadFileInfo = []
      this.uploadFileName = ''
    },
    resetTaxTemplate () {
      this.taxTemplate = {
        tplRno: null,
        rcivNm: '',
        rcivAddr: '',
        rcivBzrgNo: '',
        tpopBsnCd: '',
        rcivPicNm: '',
        rcivCtfc: '',
        emlAddr: '',
        extblIssYn: 'N',
        rcvProcCatCd: '01',
        acshIssKind: '02',
        reqCont: '',
        reqAddCont: '',
        file: null,
        acshIssDt: this.beforeIssDt
      }
      this.uploadFileInfo = []
    },
    resetDext5 () {
      this.openFileUploader()
      this.closeFileUploadr()
    },
    chanageAcshIssDt (data) {
      this.taxTemplate.acshIssDt = data
    },
    uploadFile (fileInfo) {
      this.uploadFileInfo[0].isNew = true
    },
    openFileUploader () {
      this.isShowDext5 = true
      this.isShowDext5Pop = true
      this.dext5Idx++
      this.$ekmtcCommon.layerOpen('#fb_dext5_pop > .popup_dim')
    },
    fileUploadAlert () {
      let obj = {
        useConfirmBtn: false,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.ONEX010P100.034'),
        type: 'info',
        onClose: this.openFileUploader,
        message: `<div>${this.$t('tem.CMAT042')}</div>`
      }
      this.$ekmtcCommon.alert(obj)
    },
    closeFileUploadr () {
      this.isShowDext5 = false
      this.isShowDext5Pop = false
      this.$ekmtcCommon.layerClose('#fb_dext5_pop > .popup_dim')
    },
    comAlert (msg) {
      let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.MYIN040G010.045'), // 닫기
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    reset () {
      this.resetTaxTemplate()
      this.resetDext5()
      this.taxTemplateSelect = 9999
      this.uploadFileInfo = []
      this.uploadFileName = ''
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-rcivNm'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-rcivAddr'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-rcivBzrgNo'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-file'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-emlAddr'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-rcivPicNm'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-rcivCtfc'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-acshIssDt'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-tpopBsnCd'))
    },
    parseEmitTaxTemplate () {
      let res = JSON.parse(JSON.stringify(this.taxTemplate))

      if (res.extblIssYn === null) {
        res.extblIssYn = 'N'
      }

      if (this.uploadFileInfo.length > 0) {
        res.file = this.uploadFileInfo[0]
      } else {
        res.file = null
      }

      if (res.acshIssKind === '01') {
        //res.acshIssDt = ''
      }

      return res
    },
    emitData (type) {
        let res = null
        switch (type) {
        case 'taxTemplate':
            res = this.parseEmitTaxTemplate()
            break
        case 'disabled':
          this.setDisabled()
            break
        }
        return res
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    validationCheckByte (id, maxlength) {
      let isOk = true
      const frm = document.querySelector('#div_tax_area')
      const elem = frm.querySelector('#byte_' + id)

      let byte = elem.innerHTML

      if (Number(byte.replace(/,/g, '')) > maxlength) {
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#' + id), this.$t('msg.CSBK100.182')) // 내용이 너무 많습니다.
        isOk = false
      }

      return isOk
    },
    setDisabled () {
      $('.vaAccntInfoPopup input[type=text]').prop('disabled', true)
      $('.vaAccntInfoPopup input[type=radio]').prop('disabled', true)
      $('.vaAccntInfoPopup input[type=checkbox]').prop('disabled', true)
      $('.vaAccntInfoPopup select').prop('disabled', true)
      $('.vaAccntInfoPopup textarea').prop('disabled', true)
    }
  }
}
</script>

<style scoped>
  .div_req_byte {width: max-content; float: right;}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      [
        _c("e-breadcrumbs", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.parentData.isWorking && !!_vm.parentData.isCalc,
              expression: "!!parentData.isWorking && !!parentData.isCalc",
            },
          ],
        }),
        _vm.$router.history.current.name !== "calcIssueMain"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.parentData.isWorking,
                    expression: "!parentData.isWorking",
                  },
                ],
                staticClass: "flex_box",
              },
              [
                _c(
                  "h1",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.$router.history.current.name !== "calcIssueMain",
                        expression:
                          "$router.history.current.name !== 'calcIssueMain'",
                      },
                    ],
                    staticClass: "page_title",
                  },
                  [_vm._v(_vm._s(_vm.$t("menu.MENU06.070")))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.$router.history.current.name !== "calcIssueMain",
                        expression:
                          "$router.history.current.name !== 'calcIssueMain'",
                      },
                    ],
                    staticClass: "ml_auto mt20",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button md",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.openPopup("DemDetTariffPop", null)
                          },
                        },
                      },
                      [_vm._v("DEM/DET Tariff")]
                    ),
                    _c(
                      "a",
                      {
                        class: _vm.auth.login
                          ? "button md print ml5"
                          : "button md print ml5 disabled",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.screenToPdfDownload()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "btn_icon print" }),
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.002")) + " "),
                      ]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ],
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.parentData.isWorking,
              expression: "!parentData.isWorking",
            },
          ],
          staticClass: "content_box",
        },
        [
          _c("table", { staticClass: "tbl_search t2" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c(
                      "span",
                      {
                        staticClass: "dv wid150",
                        staticStyle: { width: "200px !important" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.eiCatCd,
                              expression: "params.eiCatCd",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "eiCatCd-O",
                            name: "type",
                            value: "O",
                          },
                          domProps: {
                            checked: _vm._q(_vm.params.eiCatCd, "O"),
                          },
                          on: {
                            change: [
                              function ($event) {
                                return _vm.$set(_vm.params, "eiCatCd", "O")
                              },
                              function ($event) {
                                return _vm.changeCalcEiCatCd()
                              },
                            ],
                          },
                        }),
                        _c(
                          "label",
                          { staticClass: "mr20", attrs: { for: "eiCatCd-O" } },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.SETT060G010.003"))),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.eiCatCd,
                              expression: "params.eiCatCd",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "eiCatCd-I",
                            name: "type",
                            value: "I",
                          },
                          domProps: {
                            checked: _vm._q(_vm.params.eiCatCd, "I"),
                          },
                          on: {
                            change: [
                              function ($event) {
                                return _vm.$set(_vm.params, "eiCatCd", "I")
                              },
                              function ($event) {
                                return _vm.changeCalcEiCatCd()
                              },
                            ],
                          },
                        }),
                        _c("label", { attrs: { for: "eiCatCd-I" } }, [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.SETT060G010.004"))),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "dv wid150" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.dtKnd,
                              expression: "params.dtKnd",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.params,
                                "dtKnd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "BL" } }, [
                            _vm._v("B/L No."),
                          ]),
                        ]
                      ),
                    ]),
                    _vm.params.dtKnd == "BL"
                      ? _c("span", { staticClass: "dv wid70" }, [
                          _c("input", {
                            attrs: {
                              type: "text",
                              value: "KMTC",
                              disabled: "disabled",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.num,
                            expression: "params.num",
                          },
                        ],
                        staticClass: "wid150",
                        staticStyle: {
                          margin: "0",
                          "text-transform": "uppercase",
                        },
                        attrs: { type: "text", id: "num" },
                        domProps: { value: _vm.params.num },
                        on: {
                          blur: function ($event) {
                            return _vm.toUpperCase($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "num", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.getFreeTime()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.SETT060G010.005")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "content_box",
          class: { mt10: !_vm.parentData.isWorking },
        },
        [
          _vm._l(_vm.tableArr, function (t, i) {
            return [
              t.isShow
                ? _c("calc-table", {
                    key: "calcTable-" + i,
                    attrs: {
                      "ctr-cd": _vm.ctrCd,
                      "is-first-calc-table-index":
                        i === _vm.firstCalcTableIndex,
                      table: t,
                      query: _vm.freeTimeData.csbl400quiryinfo,
                      "an-check": _vm.freeTimeData.anCheck,
                      "user-type": _vm.userType,
                      auth: _vm.auth,
                      "table-info": _vm.calcTableInfo(i),
                      "vat-chk": _vm.vatChk,
                    },
                    on: {
                      child: _vm.getChildData,
                      _openPopup: _vm._openPopup,
                      _screenToPdfDownload: _vm.screenToPdfDownload,
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm.ctrCd == "KR" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(1),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(2),
          ])
        : _vm._e(),
      _vm.ctrCd == "KR" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(3),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(4),
          ])
        : _vm._e(),
      _vm.ctrCd == "JP" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(5),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(6),
          ])
        : _vm._e(),
      _vm.ctrCd == "JP" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(7),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(8),
          ])
        : _vm._e(),
      _vm.ctrCd == "TH" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(9),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(10),
          ])
        : _vm._e(),
      _vm.ctrCd == "VN" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(11),
          ])
        : _vm._e(),
      _vm.ctrCd == "VN" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(12),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(13),
          ])
        : _vm._e(),
      _vm.ctrCd == "MY" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(14),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(15),
          ])
        : _vm._e(),
      _vm.ctrCd == "PH" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(16),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(17),
          ])
        : _vm._e(),
      _vm.ctrCd == "PH" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(18),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(19),
          ])
        : _vm._e(),
      _vm.ctrCd == "ID" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(20),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(21),
          ])
        : _vm._e(),
      _vm.ctrCd == "ID" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(22),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(23),
          ])
        : _vm._e(),
      _vm.ctrCd == "SG" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(24),
          ])
        : _vm._e(),
      _vm.ctrCd == "SG" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(25),
          ])
        : _vm._e(),
      _vm.ctrCd == "IN" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(26),
          ])
        : _vm._e(),
      _vm.ctrCd == "IN" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(27),
          ])
        : _vm._e(),
      _vm.ctrCd == "HK" && _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(28),
          ])
        : _vm._e(),
      _vm.ctrCd == "HK" && _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(29),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(30),
          ])
        : _vm._e(),
      _vm.ctrCd == "CN" &&
      _vm.memberDetail.bkgPlcCd == "NBO" &&
      _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(31),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(32),
          ])
        : _vm._e(),
      _vm.ctrCd == "CN" &&
      _vm.memberDetail.bkgPlcCd == "XMN" &&
      _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(33),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(34),
          ])
        : _vm._e(),
      _vm.ctrCd == "CN" &&
      _vm.memberDetail.bkgPlcCd == "XMN" &&
      _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(35),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(36),
          ])
        : _vm._e(),
      _vm.ctrCd == "CN" &&
      _vm.memberDetail.bkgPlcCd == "NKG" &&
      _vm.params.eiCatCd == "O"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(37),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(38),
          ])
        : _vm._e(),
      _vm.ctrCd == "CN" &&
      _vm.memberDetail.bkgPlcCd == "NKG" &&
      _vm.params.eiCatCd == "I"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Freetime Calculation"),
            ]),
            _vm._m(39),
            _c("br"),
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v("Precautions"),
            ]),
            _vm._m(40),
          ])
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "freetime_fn_pop" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.parentInfo,
                      "freight-bill-info": _vm.parentInfo,
                      "user-dmd-info": _vm.userDmdInfo,
                      "ei-cat-cd": _vm.eiCatCd,
                      "freetime-exp-imp-rtn-sac-yn": _vm.freetimeExpImpRtnSacYn,
                    },
                    on: {
                      close: _vm.closePopup,
                      child: _vm.getChildData,
                      openPreviewPopup: _vm.openPreviewPopup,
                      openVaAccntPopup: _vm.openVaAccntPopup,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "freetime_fn_pop_child" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent2
                ? _c(_vm.customComponent2, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.parentInfo2,
                      "freetime-exp-imp-rtn-sac-yn": _vm.freetimeExpImpRtnSacYn,
                    },
                    on: { close: _vm.closePopup2, child: _vm.getChildData },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "win-layer-pop",
            { staticClass: "va_popup_list" },
            [
              _vm.customComponent3
                ? _c(_vm.customComponent3, {
                    tag: "component",
                    attrs: { "parent-info": _vm.parentInfo3 },
                    on: { close: _vm.closePopup3 },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" 수출(START DATE) "),
            _c("br"),
            _vm._v(" DEM : 실제 컨테이너 반입일 "),
            _c("br"),
            _vm._v(" DET : 빈 컨테이너 반출일 "),
            _c("br"),
            _vm._v(" MOR : RF컨테이너 전원 공급일 "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              ' OVER DAY 요율은 상단 "DEM/DET Tariff" 클릭 하시면 조회 가능합니다. '
            ),
            _c("br"),
            _vm._v(
              " 최초 정산 이 후 Freetime 연장요청은 불가하오니 정산 전 연장요청 바랍니다. "
            ),
            _c("br"),
            _vm._v(' 입금 후 정산건은 "입금/계산서 정보입력" 바랍니다. '),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" 수입(START DATE) "),
            _c("br"),
            _vm._v(" OFC : 컨테이너 입항일 "),
            _c("br"),
            _vm._v(" (OFC= DEM+DET 통합적용) "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              ' OVER DAY 요율은 상단 "DEM/DET Tariff" 클릭 하시면 조회 가능합니다. '
            ),
            _c("br"),
            _vm._v(
              " 최초 정산 이 후 Freetime 연장요청은 불가하오니 정산 전 연장요청 바랍니다. "
            ),
            _c("br"),
            _vm._v(' 입금 후 정산건은 "입금/계산서 정보입력" 바랍니다. '),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" 1.Start Date "),
            _c("br"),
            _vm._v(" DEMの場合 : 実入りコンテナの搬入日 "),
            _c("br"),
            _vm._v(" DETの場合 : 空コンテナの搬出日 "),
            _c("br"),
            _vm._v(" 2. 計算方法 "),
            _c("br"),
            _vm._v(
              " 港によりFreetime のカウント方法が異なるため、費用発生時には弊社より別途ご連絡致します。 "
            ),
            _c("br"),
            _vm._v(
              " 不明な点がありましたら弊社 Customer Service Teamまでお問い合わせください。 "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " 1. こちらは輸出のPOL側のFreetimeをご確認いただくページとなります。 "
            ),
            _c("br"),
            _vm._v(
              ' 2. POD側のFreetimeにつきましては"Freetime Request Detail"ボタン(延長している場合)、または "DEM/DET Tariff" メニュー から日数をご確認下さい。 '
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" 1. Start Date "),
            _c("br"),
            _vm._v(" DEMの場合 : 本船の入港日 "),
            _c("br"),
            _vm._v(" DETの場合 : 実入りコンテナの搬出日 "),
            _c("br"),
            _vm._v(
              " 2. 計算方法 : Start Dateからカウントします。 (* 土日祭日を含む） "
            ),
            _c("br"),
            _vm._v(" 3. OT/FRのOOG CargoはDirect Deliveryとなります。 "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " 1. DEMのStart Dateは本船の実際の入港日によって変動します。 "
            ),
            _c("br"),
            _vm._v(
              " それにより、Gate-out Deadlineが変わる場合もございますので、ご注意ください。 "
            ),
            _c("br"),
            _vm._v(
              " 2. 請求書はPODの代理店にて発行しますので、発行のご依頼は代理店にお問い合わせください。 "
            ),
            _c("br"),
            _vm._v(" * 連絡先 : http://www.kmtcjapan.com/home/port_agent.asp "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Freetime DEM/DET start date : "),
            _c("br"),
            _vm._v(" DEM start actual berth date "),
            _c("br"),
            _vm._v(" DET start gate out full date "),
            _c("br"),
            _c("br"),
            _vm._v(
              " ***LCH HZ move to HZ warehouse immediately after discharge*** "
            ),
            _c("br"),
            _vm._v(
              " ****DLY port LKB/SCT/BMT start gate in full at DLY port date**** "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" DEM/DET combined cannot apply at TH side "),
            _c("br"),
            _c("br"),
            _vm._v(" ***LCH HZ cargo often occurs DET charge (is incorrect) "),
            _c("br"),
            _vm._v(
              " because cargo movement shows gate out full from LCH to HZ warehouse "
            ),
            _c("br"),
            _vm._v(" but not show gate in full date at HZ warehouse*** "),
            _c("br"),
            _c("br"),
            _vm._v(
              " ****BMT/SCT/BKK by BARGE did not show any detail on the website, "
            ),
            _c("br"),
            _vm._v(" Cnee should contact CS team to check free time**** "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" 1.Export "),
            _c("br"),
            _vm._v(" Start: Pick up date "),
            _c("br"),
            _vm._v(" End:ETD "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Start Date "),
            _c("br"),
            _vm._v(" DEM: actual berthing date "),
            _c("br"),
            _vm._v(" DET: gate out date "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" 1. Free time DET (Combined) is not applied in VN. "),
            _c("br"),
            _vm._v(
              " 2. Free time application after invoice issue is not applied. "
            ),
            _c("br"),
            _vm._v(
              " 3. Storage Occurrence Sensing : STORAGE included in DEM, Direct calculation ICD, TCC. "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " DEM : From actual container discharge until container gate out port "
            ),
            _c("br"),
            _vm._v(
              " DET : From container gate out port until empty return to depo "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" D&D in eKMTC is for reference only "),
            _c("br"),
            _vm._v(
              " Actual amount need to check with our contractor agent (DND Control (M) Sdn Bhd) "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" DET Start Date - Actual GTO-OB-E "),
            _c("br"),
            _vm._v(" DET End Date - Actual GTI-OB-F "),
            _c("br"),
            _vm._v(" DEM Start Date - Actual GTI-OB-F "),
            _c("br"),
            _vm._v(" DEM End Date - Actual LDG-OB-F "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " 12% VAT is included in the Grand Total calculation for DEM/DET. "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" DEM Start Date - Actual Vesse's Berthing "),
            _c("br"),
            _vm._v(" DEM End Date - Actual GTO-IB-F "),
            _c("br"),
            _vm._v(" DET Start Date - Actual GTO-IB-F "),
            _c("br"),
            _vm._v(" DET End Date - Actual GTI-IB-E "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " 12% VAT is included in the Grand Total calculation for DEM/DET. "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Export : DET (Combined) "),
            _c("br"),
            _vm._v(" Start Date : Gate out Depot "),
            _c("br"),
            _vm._v(" End Date : Load on Vessel "),
            _c("br"),
            _c("br"),
            _vm._v(" Tariff : Available in link Local Brochure KMTC 2023 "),
            _c("br"),
            _vm._v(
              " Home -> Customer Service -> FAQ (JKT Inbound link guidance) "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Pickup Empty container within normal freetime (7 Days) "),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Import : DET (Combined) "),
            _c("br"),
            _vm._v(" Start Date : Actual Vessel Berthing "),
            _c("br"),
            _vm._v(" End Date : Container returned at Depot KMTC "),
            _c("br"),
            _c("br"),
            _vm._v(" Tariff : Available in link Local Brochure KMTC 2023 "),
            _c("br"),
            _vm._v(
              " Home -> Customer Service -> FAQ (JKT Inbound link guidance) "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " Empty return to Depot as per D/O validation date and deliver to KMTC Depot address as mentioned on D/O. "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " Export : DET (Combined) - 06 calendar days (for all types) "
            ),
            _c("br"),
            _vm._v(" Start Date : 6 days before vessel ETA SIN "),
            _c("br"),
            _c("br"),
            _vm._v(
              " Depot PIC can access to ICC, depot will release container according to pick up date which is reflected under the ICC menu "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Depot KMTC operating hour (Mon – Fri 8am ~ 5pm, Sat 8am ~ 12pm, closed on Sun) "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " Import : DET (Combined) - 06 calendar days (for all types) "
            ),
            _c("br"),
            _vm._v(" Start Date : Vessel completion of discharge "),
            _c("br"),
            _c("br"),
            _vm._v(
              " 1) Consignee / clearing agent / haulier may call or email KMTC SG office for checking of free detention or detention charges may arise. "
            ),
            _c("br"),
            _vm._v(
              " 2) Depot PIC can access to ICC, when haulier return container, actual detention charges will be informed for their direct settlement to depot. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " We start guide consignee / clearing agent / haulier to check free detention or detention charges thru KMTC website (https://www.ekmtc.com/index.html#/main) "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Depot KMTC operating hour (Mon – Fri 8am ~ 5pm, Sat 8am ~ 12pm, closed on Sun) "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Detention "),
            _c("br"),
            _vm._v(" Start Date - Empty gate-out depot "),
            _c("br"),
            _vm._v(
              " End Date - (1) Laden gate-in at Terminal if POR is Port (2) Laden gate-in at ICD for POR-ICDs "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" DET (Combined) "),
            _c("br"),
            _vm._v(
              " Start Date - (1) Actual Vessel Berthing if FPD is Port (2) Laden arrival at FPD if ICD "
            ),
            _c("br"),
            _vm._v(" End Date - Actual empty return to depot "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" DEM start date - Full container GTI date "),
            _c("br"),
            _vm._v(" DET start date - Empty container GTO date "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " DEM start date : follow the start date in [Demurrage start correction] "
            ),
            _c("br"),
            _vm._v(" DET start date : full container GTO date. "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(
              " 1. Do not accept the freetime approval of DET(Combined) for HKG local cargo. "
            ),
            _c("br"),
            _vm._v(
              " 2. The freetime of HZ and OOG is 72hrs and 24 hrs, the DEM invoice may not correct in e-KMTC, cnee should check with IMP team first. "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" DET(conbined) "),
            _c("br"),
            _vm._v(" Start date: Empty pick-up date "),
            _c("br"),
            _vm._v(" End date: Estimated time of departure "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Shipback cargo "),
            _c("br"),
            _vm._v(
              " Start: Arrival date(imbound),End:Departure date(shipback) "
            ),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Dem (incl DET) "),
            _c("br"),
            _vm._v(" Start:Emty pick up date "),
            _c("br"),
            _vm._v(" End:departure date "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" 1. Shipback cargo "),
            _c("br"),
            _vm._v(
              " Start: Arrival date(imbound),End:Departure date(shipback) "
            ),
            _c("br"),
            _vm._v(
              " 2. If you apply to get EIR in advance, you are responsible for dem(incl det)charge. "
            ),
            _c("br"),
            _vm._v(" 3. Free time tariff of DG same as one of non DG. "),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Dem (incl DET) "),
            _c("br"),
            _vm._v(" Start:Arrival date "),
            _c("br"),
            _vm._v(" End:Return to depot date "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [_c("li", [_vm._v(" Free time tariff of DG same as one of non DG. ")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Export (Combined)---Feeder "),
            _c("br"),
            _vm._v(" Start:Empty pick up time---Movements updated manually "),
            _c("br"),
            _vm._v(
              " End:Export full on board date---Automatically link leg vessel time "
            ),
            _c("br"),
            _vm._v(" (Declared shipment date, not actual shipment date) "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Normal 7 days free time "),
            _c("br"),
            _vm._v(" If need extend freetime,pls send request "),
            _c("br"),
            _vm._v(" through EKMTC asap "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" Import (Combined)---Feeder "),
            _c("br"),
            _vm._v(
              " Start:IB Full discharge time(Actual DIS time)---Manually "
            ),
            _c("br"),
            _vm._v(" End:Empty return time---Manually "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont text_left" }, [
      _c(
        "ul",
        { staticClass: "bul_list_sm", staticStyle: { "list-style": "none" } },
        [
          _c("li", [
            _vm._v(" D&D in eKMTC is only for reference. "),
            _c("br"),
            _vm._v(" Actuacl amount pls contact with local KMTC office. "),
            _c("br"),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }